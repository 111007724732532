import "../styles/footer.css";

export default () => {
  const showPointer = () => {
    document.getElementById("mouse-pointer")?.classList.add("show");
  };
  const hidePointer = () => {
    document.getElementById("mouse-pointer")?.classList.remove("show");
  };
  return (
    <footer>
      <p>
        Illustrations from{" "}
        <a
          onMouseEnter={showPointer}
          onMouseLeave={hidePointer}
          href="https://absurd.design/"
          target="_blank"
        >
          absurd.design
        </a>
        .
      </p>
    </footer>
  );
};
