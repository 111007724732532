import { useEffect, useState, useRef } from "react";
import { CiCircleChevDown } from "react-icons/ci";
import classes from "../styles/landing.module.css";

export default () => {
  const [cursorRef, setCursorRef] = useState(null);
  const [headingRef, setHeadingRef] = useState(null);

  const writing = useRef(null);
  const [opacity, setOpacity] = useState(1);

  const wait = (ms) => new Promise((res) => setTimeout(res, ms));

  const handleSpanLoop = async () => {
    while (true) {
      cursorRef.style.visibility = "visible";
      await wait(600);
      if (!writing.current) {
        cursorRef.style.visibility = "hidden";
        await wait(600);
      }
    }
  };

  const handleWrite = (text) =>
    new Promise(async (res) => {
      writing.current = true;
      while (headingRef.innerHTML.length > 0) {
        headingRef.innerHTML = headingRef.innerHTML.slice(0, -1);
        await wait(80);
      }
      writing.current = false;
      await wait(1000);
      writing.current = true;
      for (const char of text) {
        headingRef.innerHTML += char;
        const time = 100 + Math.random() * 300;
        await wait(time);
      }
      writing.current = false;
      res();
    });

  const writeRoutine = async () => {
    while(true){
      await handleWrite("Welcome to my website!!");
      await wait(4000);
      await handleWrite("Feel free to look around.");
      await wait(4000);
    }
  };

  const handleScroll = () => {
    const e = document.getElementById("root");
    const pos = Math.min(e.scrollTop / e.clientHeight, 1);
    setOpacity(1 - pos);
  };

  const scrollBottom = () => {
    const e = document.getElementById("root");
    e.scrollTo({ top: e.clientHeight + 1, behavior: "smooth" });
  };

  useEffect(() => {
    document.getElementById("root").addEventListener("scroll", handleScroll);
    handleScroll();
  }, []);

  useEffect(() => {
    if (cursorRef) {
      handleSpanLoop();
    }
  }, [cursorRef]);

  useEffect(() => {
    if (headingRef) {
      writeRoutine();
    }
  }, [headingRef]);

  return (
    <>
      <div className={classes.container} id="landing" style={{ opacity }}>
          <div>
        <h1>
          <span ref={setHeadingRef} />
          <span className={classes.cursor} ref={setCursorRef}>
          |
        </span>
        </h1>
        </div>
      </div>
      <div className={classes.touch}>
        <button
          style={opacity !== 1 ? { opacity: 0, pointerEvents: "none" } : {}}
          onClick={scrollBottom}
        >
          <CiCircleChevDown color="black" size={"2rem"} />
        </button>
      </div>
    </>
  );
};
