import { useEffect, useState } from "react";
import classes from "../styles/navBar.module.css";
import { FaInstagram, FaLinkedin, FaGithub } from "react-icons/fa";

export default () => {
  const [ref, setRef] = useState(null);
  const signature = process.env.PUBLIC_URL+"/images/signature.jpg"; 

  const scrollUp = () => {
    const e = document.getElementById("root");
    e.scrollTo(0, 0);
  };
  const scrollById = (id) => {
    const e = document.getElementById(id);
    const root = document.getElementById("root");
    if (!(ref && e && root)) {
      return;
    }
    const idOffset = e.offsetTop;
    const navHeight = ref.clientHeight;
    root.scrollTo(0, idOffset - navHeight);
  };

  useEffect(() => {
    console.log("NAVBAR LOAD");
  }, []);

  return (
    <nav ref={setRef} className={classes.container} id="nav-bar">
      <div className={classes.section}>
        <img src={signature} onClick={scrollUp}/>
        <a onClick={() => scrollById("portfolio")}>Portfolio</a>
        <a onClick={() => scrollById("contact")}>Contact</a>
        <a onClick={() => scrollById("resume")}>Resume</a>
      </div>

      <div className={classes.section}>
        <a href="https://www.instagram.com/luca_tozzini/" target="_blank">
          <FaInstagram color="black" size={"1.5rem"} />
        </a>
        <a
          href="https://www.linkedin.com/in/luca-tozzini-681145238/"
          target="_blank"
        >
          <FaLinkedin color="black" size={"1.5rem"} />
        </a>
        <a href="https://github.com/LucaTozzini" target="_blank">
          <FaGithub color="black" size={"1.5rem"} />
        </a>
      </div>
    </nav>
  );
};
