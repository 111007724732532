// React
import { useEffect } from "react";

//
import { BrowserView } from "react-device-detect";

// CSS
import classes from "./styles/app.module.css";

// Components
import NavBar from "./components/NavBar.component";
import Landing from "./components/Landing.component";
import Skills from "./components/Skills.component";
import Portfolio from "./components/Portfolio.component";
import Bio from "./components/Bio.component";
import Footer from "./components/Footer.component";
import Resume from "./components/Resume.component";
import Contact from "./components/Contact.component";
import Mouse from "./components/Pointer.component";

function App() {
  useEffect(() => {
    document.getElementById("root").classList.add("reveal");
  }, []);
  return (
    <>
      <BrowserView>
        <Mouse />
      </BrowserView>
      <NavBar />
      <Landing />
      <div className={classes.border} />
      <div className={classes.components}>
        <Bio />
        <Portfolio />
        <Contact />
        <Resume />
      </div>
      <Skills />
      <Footer />
    </>
  );
}

export default App;
