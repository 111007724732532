import { useEffect } from "react";
import classes from "../styles/portfolio.module.css";

export default () => {
  const showPointer = () => {
    document.getElementById("mouse-pointer")?.classList.add("show");
  };
  const hidePointer = () => {
    document.getElementById("mouse-pointer")?.classList.remove("show");
  };

  useEffect(() => {
    const videos = document.getElementsByClassName(classes.videoPortrait);
    for(const video of videos) {
      video.play();
    }
  }, []);

  const Project = ({ img, video, title, description, url }) => {
    return (
      <a
        className={classes.project}
        target="_blank"
        href={url}
        onMouseEnter={showPointer}
        onMouseLeave={hidePointer}
      >
        {video ? <video muted className={classes.videoPortrait} autoPlay loop>
          <source src={video}/>
        </video> : <></>}
        <div className={classes.text}>
          <h3>{title}</h3>
          <p>{description}</p>
        </div>
      </a>
    );
  };

  return (
    <div className={classes.container} id="portfolio">
      <h1>This is what I've been working on</h1>
      <div className={classes.projects}>
        <Project
          title={"Media Server & Organizer + Client"}
          description={`Full-stack application for organizing movies and shows (or torrenting through the server). Uses TMDb API for media data, openSubtitles.com for on-demand subtitles, and the YIFI API for torrent URIs. Client app (web & mobile) developed with React.`}
          video={process.env.PUBLIC_URL + "/videos/Betflix_Showcase.mp4"}
          url={"https://github.com/LucaTozzini/Betflix"}
        />
        <Project
          title={"Public Transit App"}
          description={`Full-stack application for bus and route tracking using Google's General Transit Feed Specification (GTFS). Using node.js for backend data collection and REST API, and EJS for the front-end client app.`}
          url={"https://github.com/LucaTozzini/BC-Transit-Bus-Monitoring"}
        />
      </div>
    </div>
  );
};
