import { useEffect, useState } from "react";
import "../styles/pointer.css";

import { FaExternalLinkAlt } from "react-icons/fa";

export default () => {
  const [ref, setRef] = useState(null);
  useEffect(() => {
    if (ref) {
      document.body.addEventListener("mousemove", (e) => {
        ref.style.left = e.clientX + "px";
        ref.style.top = e.clientY + "px";
      });
    }
  }, [ref]);
  return (
    <div id="mouse-pointer" ref={setRef}>
      <FaExternalLinkAlt />
    </div>
  );
};
