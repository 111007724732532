import classes from "../styles/bio.module.css";
export default () => {
  const Image = process.env.PUBLIC_URL + "/images/absurd.design - Chapter 1 - 08.png";
  const Emoji = process.env.PUBLIC_URL + "/images/waving-hand-sign_1f44b.png"
  const showPointer = () => {
    document.getElementById("mouse-pointer")?.classList.add("show");
  }
  const hidePointer = () => {
    document.getElementById("mouse-pointer")?.classList.remove("show");
  }
  return (
    <div id="bio" className={classes.container}>
      <div>
        <h2>
          Hi,{" "}
          <img
            src={Emoji}
            width={40}
            height={40}
          />
          <br /> I'm Luca Tozzini
        </h2>
        <p>
          I'm currently in my 3rd year of Computer Science at{" "}
          <a onMouseEnter={showPointer} onMouseLeave={hidePointer} target="_blank" href="https://www.uvic.ca">
            UVic
          </a>
          . In my spare time I develop websites and mobile apps, as well as
          backend applications. Here you can take a look at what I've been up
          to, or get in touch with me.
        </p>
      </div>
      <img src={Image} alt="wave image" />
    </div>
  );
};
