import classes from "../styles/resume.module.css";

export default () => {
  const showPointer = () => {
    document.getElementById("mouse-pointer")?.classList.add("show");
  }
  const hidePointer = () => {
    document.getElementById("mouse-pointer")?.classList.remove("show");
  }
  return (
    <div className={classes.container} id="resume">
      <h1>Here's My Resume</h1>
      <a
        onMouseEnter={showPointer}
        onMouseLeave={hidePointer}
        className={[classes.link, "showMouse"].join(" ")}
        href={process.env.PUBLIC_URL + "/pdf/resume.pdf"}
        target="_blank"
      >
        <img
          className={classes.image}
          src={process.env.PUBLIC_URL + "/images/resume-1.png"}
        />
      </a>
    </div>
  );
};
