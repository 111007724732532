import classes from "../styles/contact.module.css";

export default () => {
  const Image =
    process.env.PUBLIC_URL + "/images/absurd.design - Chapter 1 - 01.png";

  return (
    <div className={classes.container} id="contact">
      <h1>Want to get in touch?</h1>
      <div>
        <img src={Image} />
        <a target="_blank" href="mailto:contact@lucatozzini.com">
          Send Me An Email
        </a>
      </div>
    </div>
  );
};
